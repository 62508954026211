import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    chatUser,
    fetchChatAllUsersStart,

    fetchMoreChatAllUsersStart
} from "../../store/actions/ChatAction";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {t, translate} from "react-multi-lang";
import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";

const DirectChat = (props) => {
    const page = useRef(1);

    const currentPageLength = useRef(0);
    useEffect(() => {
        page.current = 1
        currentPageLength.current = 0

        props.dispatch(fetchChatAllUsersStart({
            name: props.search,
            filter: props.selectedChatUser,
            page: 1,
            sort: props.selectedSortChatUser
        }));

        if (document.getElementById('usersDiv')) {
            document.getElementById('usersDiv').scrollTop = 0
        }

    }, [props.search, props.selectedChatUser, props.selectedSortChatUser]);

    const fetchMoreUsers = () => {
        page.current = page.current + 1;
        props.dispatch(fetchMoreChatAllUsersStart({
            page: page.current,
            name: props.search,
            filter: props.selectedChatUser,
            sort: props.selectedSortChatUser
        }));
        if (props.allChatUsers.data && props.allChatUsers.data.users) {
            let calculatedTotal = 0; // Initialize the total inside this effect

            for (const key in props.allChatUsers.data.users) {
                if (props.allChatUsers.data.users.hasOwnProperty(key)) {
                    calculatedTotal += props.allChatUsers.data.users[key].length;

                }
            }

            currentPageLength.current = calculatedTotal




        } else {
            currentPageLength.current = 0; // Reset to 0 if no users
        }
    }

    return (
        <div className="new-chat-list-wrapper-card">
            {props.allChatUsers.loading ?
                <div className="new-chat-list-box">
                    {[...Array(6)].map((val, i) =>
                        <div className="new-chat-list-card" key={i}>
                            <div className="new-chat-list-user-msg-sec">
                                <div className="new-chat-list-user-img-sec">
                                    <Skeleton circle={true} className="new-chat-list-user-img"/>
                                </div>
                                <div className="new-chat-list-user-msg">
                                    <Skeleton height={50}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                : props.allChatUsers.data.users &&
                Object.entries(props.allChatUsers?.data?.users)?.length > 0 ?
                    <>
                        <div style={{
                            maxHeight: 'calc(100vh - 190px)',
                            overflowY: 'auto',
                            // paddingRight: '1em',
                            marginTop: '2em'
                        }} id="usersDiv">
                            <InfiniteScroll
                                dataLength={currentPageLength.current}
                                next={fetchMoreUsers}
                                hasMore={currentPageLength.current < props.allChatUsers?.data?.total}
                                loader={<div className="new-chat-list-box">
                                    {[...Array(6)].map((val, i) =>
                                        <div className="new-chat-list-card" key={i}>
                                            <div className="new-chat-list-user-msg-sec">
                                                <div className="new-chat-list-user-img-sec">
                                                    <Skeleton circle={true} className="new-chat-list-user-img"/>
                                                </div>
                                                <div className="new-chat-list-user-msg">
                                                    <Skeleton height={50}/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                }
                                scrollableTarget="usersDiv"
                            >

                                {Object.entries(props.allChatUsers?.data?.users).map(([key]) => {


                                    return (
                                        <div key={key}>
                                            <div className="letter-group-header">{key}</div>
                                            <div className="new-chat-list-box">
                                                {props.allChatUsers.data.users[key].map((user, i) =>
                                                        <div className="new-chat-list-card"
                                                             key={i}
                                                             onClick={() => props.dispatch(chatUser(user))}
                                                             style={{position: "relative"}}>
                                                            {
                                                                user?.share_post_status === 0 && <span style={{
                                                                    background: "red",
                                                                    height: "9px",
                                                                    width: "9px",
                                                                    borderRadius: "50%",
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "8px"
                                                                }}/>
                                                            }
                                                            <div className="new-chat-list-user-msg-sec">
                                                                <div className="new-chat-list-user-img-sec">
                                                                    <Image
                                                                        className="new-chat-list-user-img"
                                                                        src={user.picture}
                                                                    />
                                                                </div>
                                                                <div className="new-chat-list-user-msg">
                              <span style={{display: "flex", gap: "7px", alignItems: "center"}}>
                                <h4>{user.name}</h4>
                                  {user?.is_certified_content_creator === 2 ? (
                                      <Image
                                          style={{marginBottom: "5px"}}
                                          className="sidebar-verified-icon"
                                          src={window.location.origin + "/assets/images/new-home/verified-icon.svg"}
                                      />
                                  ) : null}
                              </span>
                                                                    {
                                                                        (user?.file_type === "share_post" && user?.from_user_id === user?.sender_id) ? (
                                                                            <p dangerouslySetInnerHTML={{__html: user.sender_msg_post}}></p>
                                                                        ) : (
                                                                            user?.file_type === "share_post" ? (
                                                                                <p dangerouslySetInnerHTML={{__html: user.message}}></p>
                                                                            ) : (
                                                                                <p>{user.message}</p>
                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="new-chat-list-notify-sec">
                                                                <div className="new-chat-list-time-sec">
                                                                    <p>{user.time_formatted}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}

                            </InfiniteScroll>
                        </div>
                    </>
                    : <>
                        {props.search ?
                            <Image
                                style={{width: "100%"}}
                                alt="No user found"
                                src={window.location.origin + "/assets/images/new-chat/no-user-found.png"}/>
                            : <div className="no-chats-container">
                                <Image
                                    style={{width: "100%"}}
                                    src={window.location.origin + "/assets/images/new-chat/no-chats-yet.png"}/>
                                <Link to="/search">
                                    {t("explore")}
                                </Link>
                            </div>
                        }
                    </>
            }
        </div>

    )
}

const mapStateToPros = (state) => ({
    allChatUsers: state.chat.allChatUsers,
    chatUser: state.chat.chatUser,
    chatMessages: state.chat.chatMessages,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(DirectChat));