import React, { useEffect, useState } from "react";
import { Modal, Card, Container, Row, Col } from "react-bootstrap";
import "./LiveStreaming.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { fetchLiveVideosListStart } from "../../store/actions/LiveVideoAction";
import LiveStreamingLoader from "../Loader/LiveStreamingLoader";
import { useLocation } from "react-router-dom";
import Environment from "../../Environment";

const LIVEKIT_SERVER_URL = "wss://fanclub-jaq3ky08.livekit.cloud";

const LiveStreamingIndex = (props) => {
  const locationD = useLocation();

  useEffect(() => {
    props.dispatch(fetchLiveVideosListStart());
  }, []);

  const [liveStreams, setLiveStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentStream, setCurrentStream] = useState(null);
  const [room, setRoom] = useState(null);
  const [isViewing, setIsViewing] = useState(false);

  useEffect(() => {
    const fetchLiveStreams = async () => {
      setLoading(true);
      try {
        const response = await Environment.getMethod("getlive", {});
        if (response.status === 200) {
          setLiveStreams(response.data);
        } else {
          console.error("Failed to fetch live streams.");
        }
      } catch (error) {
        console.error("Error fetching live streams:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLiveStreams();
  }, []);

  const fetchToken = async (roomName, participantName) => {
    try {
      const response = await fetch(
        `https://fansuatbackend.lambetech.com/livekitToken.php?roomName=${encodeURIComponent(roomName)}&participantName=${encodeURIComponent(participantName)}&role=viewer`
      );
      const data = await response.json();
      if (response.ok) return data.token;
      alert(data.error);
      return null;
    } catch (error) {
      console.error("Error fetching token:", error);
      alert("Failed to fetch token");
      return null;
    }
  };

  const startViewer = async (roomName, participantName, stream) => {
    setCurrentStream(stream);
    const token = await fetchToken(roomName, participantName);
    if (!token) return;

    const Room = window.LivekitClient.Room;
    const RoomEvent = window.LivekitClient.RoomEvent;

    const newRoom = new Room();
    setRoom(newRoom);

    newRoom.on(RoomEvent.TrackSubscribed, (track) => {
      setShowModal(true);
      
      if (track.kind === "video") {
        const videoElement = document.createElement("video");
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        document.getElementById("videos").appendChild(videoElement);
        track.attach(videoElement);
        
        videoElement.play().catch(error => console.log('Video playback failed', error));
      } else if (track.kind === "audio") {
        const audioElement = document.createElement("audio");
        audioElement.autoplay = true;
        audioElement.controls = false;
        audioElement.style.display = "none";
        audioElement.muted = false;
        audioElement.volume = 1.0;
        document.body.appendChild(audioElement);
        track.attach(audioElement);
        
        audioElement.play().catch(error => console.log('Audio playback failed', error));
      }
    });

    await newRoom.connect(LIVEKIT_SERVER_URL, token);
    console.log("Connected to room as viewer:", roomName);
    setIsViewing(true);
  };

  const stopViewing = () => {
    if (room) {
      room.disconnect();
      setRoom(null);
    }

    // Clean up video elements
    document.getElementById("videos").innerHTML = "";

    // Clean up audio elements
    document.querySelectorAll("audio").forEach(audio => {
      audio.pause();
      audio.remove();
    });

    setShowModal(false);
    setIsViewing(false);
  };

  return (
    <>
      <div className="live-streaming-sec">
        <div className="live-streaming-header-sec">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="live-streaming-header-sec">
                  {props.profile.loading ? (
                    t("loading")
                  ) : props.profile.data.is_content_creator === 2 ? (
                    <div>
                      <div className="live-streaming-left-sec">
                        <Link to="/live-videos" className={`new-live-history-btn ${locationD.pathname === "/live-videos" ? "activeLiveTab" : ""}`}>
                          {t("onlive")}
                        </Link>

                        <Link to="/live-videos-history" className="new-live-history-btn align-right">
                          {t("my_live_streams")}
                        </Link>

                        <Link to="/go-live" className={`new-go-live-btn align-right`}>
                          {t("go_live")}
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="live-streaming-sec">
                  <Container fluid>
                    <Row>
                      <Col md={12}>
                        {loading ? (
                          <p>Loading...</p>
                        ) : (
                          <Row>
                            {liveStreams.map((stream) => (
                              <Col md={4} key={stream.id} className="mb-4 text-center">
                                <Card onClick={() => startViewer(stream.roomName, stream.participantName, stream)} className="border-0 bg-transparent">
                                  <Card.Body>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                        width: "160px",
                                        height: "160px",
                                        borderRadius: "50%",
                                        background: "linear-gradient(45deg,  #fff, #6661e7, #6661e7, blue)",
                                        padding: "5px",
                                      }}
                                    >
                                      <img src={stream.user.picture} alt={stream.user.name} className="rounded-circle"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          border: "2px solid white",
                                          objectFit: "cover",
                                        }}
                                      />
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "5px",
                                          right: "5px",
                                          backgroundColor: "red",
                                          color: "white",
                                          padding: "5px 10px",
                                          borderRadius: "15px",
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        LIVE
                                      </span>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>

          <Modal show={showModal} onHide={stopViewing} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{currentStream?.title || "Live Streaming"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="videos" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}></div>
            </Modal.Body>
          </Modal>
        </div>
        {props.liveVideosList.loading ? <LiveStreamingLoader /> : null}
      </div>
      {props.profile.loading ? t("loading") : ""}
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideosList: state.liveVideo.liveVideosList,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveStreamingIndex));
