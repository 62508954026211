import React from 'react';
import Skeleton from "react-loading-skeleton";

const LiveStreamingLoader = () => {
  return (
    <>
      <div className="most-popular-live-box">
        {[...Array(8)].map((_, index) => (
          <Skeleton key={index} className="live-streaming-loader" />
        ))}
      </div>
    </>
  );
}

export default LiveStreamingLoader;
