import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_FOLLOWERS_START,
  FETCH_ACTIVE_FOLLOWERS_START,
  FETCH_EXPIRED_FOLLOWERS_START,
  FETCH_FOLLOWING_START,
  FOLLOW_USER_START,
  UNFOLLOW_USER_START,
  FETCH_ACTIVE_FOLLOWING_START,
  FETCH_EXPIRED_FOLLOWING_START,
  UNFOLLOW_USER_SUBSCRIPTION_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchFollowersFailure,
  fetchFollowersSuccess,
  fetchActiveFollowersFailure,
  fetchActiveFollowersSuccess,
  fetchExpiredFollowersFailure,
  fetchExpiredFollowersSuccess,
  fetchFollowingFailure,
  fetchFollowingSuccess,
  followUserFailure,
  followUserSuccess,
  unFollowUserFailure,
  unFollowUserSuccess,
  fetchActiveFollowingSuccess,
  fetchActiveFollowingFailure,
  fetchExpiredFollowingSuccess,
  fetchExpiredFollowingFailure,
  unFollowUserSubFailure,
  unFollowUserSubSuccess,
} from "../actions/FollowAction";

import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { fetchUnsubscribedSuccess } from "../actions/UnsubscribedAction";
import { addUserListSuccessD } from "../actions/CustomListAction";
import { fetchHomePostsSuccess } from "../actions/HomeAction";

function* followUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.follow.followUser.inputData
    );
    const response = yield api.postMethod("follow_users", inputData);
    if (response.data.success) {
      yield put(followUserSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.setItem(
        "total_followers",
        JSON.stringify(response.data.data.total_followers)
      );
      localStorage.setItem(
        "total_followings",
        JSON.stringify(response.data.data.total_followings)
      );
    } else {
      yield put(followUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(followUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* unFollowUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.follow.unFollowUser.inputData
    );
    const homePagePosts = yield select((state) => state.home.homePost.data)

    const response = yield api.postMethod("unfollow_users", inputData);
    if (response.data.success) {
      yield put(unFollowUserSuccess(response.data.data));

      const updatedHomePagePost = homePagePosts?.posts?.map(item => {
        if (item?.user_id === Number(response.data.data.user_id)) {
          return {
            ...item,
            show_follow: 1,
            show_unfollow: 0
          }
        } else {
          return item
        }
      });
      yield put(fetchHomePostsSuccess(updatedHomePagePost))
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.setItem(
        "total_followers",
        JSON.stringify(response.data.data.total_followers)
      );
      localStorage.setItem(
        "total_followings",
        JSON.stringify(response.data.data.total_followings)
      );

      if (inputData?.page === "homepage") {
        inputData.closeUnfollow();
        return;
      }
      window.location.reload();
    } else {
      yield put(unFollowUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(unFollowUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* unFollowUserSubscriptionAPI({data}) {
  try {
    // const inputData = yield select(
    //   (state) => state.follow.unFollowUser.inputData
    // );
    // debugger;
    const homePagePosts = yield select((state) => state.home.homePost.data);
    const response = yield api.postMethod("unsubscribe_users", data);
    if (response.data.success) {
      yield put(unFollowUserSubSuccess(response.data.data));

      const updatedHomePagePost = homePagePosts?.posts?.map(item => {
        if (item?.user_id === Number(response.data.data.user_id)) {
          return {
            ...item,
            payment_info: {
              ...item.payment_info,
              subscription_info: {
                ...item.payment_info.subscription_info
              },
              unsubscribe_btn_status: 0
            }
          }
        } else {
          return item
        }
      });

      console.log(updatedHomePagePost, "updatedHomePagePost")
      yield put(fetchHomePostsSuccess(updatedHomePagePost));
      const notificationMessage = getSuccessNotificationMessage(response.data.message);
      yield put(createNotification(notificationMessage));
      localStorage.setItem("total_followers",JSON.stringify(response.data.data.total_followers));
      localStorage.setItem("total_followings",JSON.stringify(response.data.data.total_followings));
      if (data?.page === "homepage") {
        data.closeUnsubscribeModel();
        return;
      }
      window.location.reload();
    } else {
      yield put(unFollowUserSubFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(unFollowUserSubFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}




function* fetchFollowersAPI() {
  try {
    const response = yield api.postMethod("followers");
    if (response.data.success) {
      yield put(fetchFollowersSuccess(response.data.data));
    } else {
      yield put(fetchFollowersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFollowersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchActiveFollowersAPI({data}) {
  try {
   
    if(data !== undefined && data.setIsLoadingList !== undefined){
      data.setIsLoadingList(true);
    }
    const filterSortPayload = data?.filterSort ? data?.filterSort : {
      filter: '',
      sort: '',
      tag_title: '',
      tag_colour: '',
      category: "",
    }

    const response = yield api.postMethod("active_followers", filterSortPayload);
    if (response.data.success) {

      if(data !== undefined && data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }

      const updatedActiveFollowers = {
        ...response.data.data,
        followers: response.data.data.followers.map((follower) => {
          return {
            ...follower,
            otherUser: {
              ...follower.otherUser,
              isSelected: false
            }
          }
        })
      }

      yield put(fetchActiveFollowersSuccess(updatedActiveFollowers));
        
      if(data !== undefined && data.allListData !== undefined){
           
        const updatedData = data.allListData.map((ele)=> {
       
          if(ele.list_name === data.typeList){

            const updatedCustomeData = response.data?.data?.followers?.map((ele)=>{
               
              return{
                ...ele,
                customListFlag : "FollowerFromList"
              }

            }) 
            
           return {
             ...ele,
             data :  updatedCustomeData,
             total : response.data?.data?.total,
             
           }

          }else {
           return ele
          }

        });

        yield put(addUserListSuccessD(updatedData));


        console.log("🚀 ~ updatedData ~ updatedData:", updatedData)
       
     } 

    } else {
      if(data !== undefined && data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }
      yield put(fetchActiveFollowersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    if(data !== undefined && data.setIsLoadingList !== undefined){
      data.setIsLoadingList(false);
    }
    yield put(fetchActiveFollowersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchExpiredFollowersAPI() {
  try {
    const response = yield api.postMethod("expired_followers");
    if (response.data.success) {
      yield put(fetchExpiredFollowersSuccess(response.data.data));
    } else {
      yield put(fetchExpiredFollowersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchExpiredFollowersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchFollowingAPI() {
  try {
    const response = yield api.postMethod("followings");
    if (response.data.success) {
      yield put(fetchFollowingSuccess(response.data.data));
    } else {
      yield put(fetchFollowingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFollowingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchActiveFollowingAPI({data}) {
  try {
    if(data !== undefined && data.setIsLoadingList !== undefined){

      data.setIsLoadingList(true);
    }

    const filterSortPayload = data?.filterSort ? data?.filterSort : {
                                filter: '',
                                sort: '',
                                tag_title: '',
                                tag_colour: '',
                                category: "",
                              }
    console.log("filterSortPayload", filterSortPayload)
    const response = yield api.postMethod("active_followings", filterSortPayload);
    if (response.data.success) {
      if(data !== undefined && data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }
      
      const updatedActiveFollowing = {
        ...response.data.data,
        followers: response.data.data.followers.map((follower) => {
          return {
            ...follower,
            otherUser: {
              ...follower.otherUser,
              isSelected: false
            }
          }
        })
      }
      yield put(fetchActiveFollowingSuccess(updatedActiveFollowing));
      
      if(data !== undefined && data.allListData !== undefined){
           
        const updatedData = data.allListData.map((ele)=> {
       
          if(ele.list_name === data.typeList){

            const updatedData = response.data?.data?.followers?.map((ele)=>{
                
              return{
                ...ele,
                customListFlag : "FollowingList"
              }
  
            });
            
           return {
             ...ele,
             data :  updatedData,
             total : response.data?.data?.total
           }

          }else {
           return ele
          }

        });

        yield put(addUserListSuccessD(updatedData));


        console.log("🚀 ~ updatedData ~ updatedData:", updatedData)
       
     } 

    } else {
      if(data !== undefined && data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }
      yield put(fetchActiveFollowingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    console.log("error custopm ", error)
    if(data !== undefined && data.setIsLoadingList !== undefined){
      data.setIsLoadingList(false);
    }
    yield put(fetchActiveFollowingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchExpiredFollowingAPI() {
  try {
    const response = yield api.postMethod("expired_followings");
    if (response.data.success) {
      yield put(fetchExpiredFollowingSuccess(response.data.data));
    } else {
      yield put(fetchExpiredFollowingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchExpiredFollowingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FOLLOW_USER_START, followUserAPI)]);
  yield all([yield takeLatest(UNFOLLOW_USER_START, unFollowUserAPI)]);
  yield all([yield takeLatest(UNFOLLOW_USER_SUBSCRIPTION_START, unFollowUserSubscriptionAPI)]);
  yield all([yield takeLatest(FETCH_FOLLOWERS_START, fetchFollowersAPI)]);
  yield all([
    yield takeLatest(FETCH_ACTIVE_FOLLOWERS_START, fetchActiveFollowersAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_EXPIRED_FOLLOWERS_START, fetchExpiredFollowersAPI),
  ]);
  yield all([yield takeLatest(FETCH_FOLLOWING_START, fetchFollowingAPI)]);
  yield all([
    yield takeLatest(FETCH_ACTIVE_FOLLOWING_START, fetchActiveFollowingAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_EXPIRED_FOLLOWING_START, fetchExpiredFollowingAPI),
  ]);
}
