import React from 'react';
import './LevelProgress.css'; // Optional: Separate CSS file
import SemiCircleProgressBar from "react-progressbar-semicircle";


const LockedPost = ({ alias_level='Senior', percentage = 75, required_level = "Level 2", coins_spend = '121' }) => {

    return (

        <div className="locked-div">

            <div className="level-progress-container">
                <SemiCircleProgressBar percentage={percentage} stroke="#c221c7" showPercentValue strokeWidth="3" />

                <div className="level-requirement">Level Requirement: {alias_level} ({required_level})</div>
                <div className="coins-requirement">Spend {coins_spend} more coins to unlock this level.</div>
            </div>
        </div>
    );
};

export default LockedPost;
