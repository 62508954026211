import React, { useState } from 'react'
import { Accordion, Card } from "react-bootstrap";
import DOMPurify from 'dompurify';

const LevelDescriptionView = ({data, index}) => {
    const [activeKey, setActiveKey] = useState(null);

    const handleToggle = (key) => {
      setActiveKey(activeKey === key ? null : key);
    };

  return (
    <Accordion activeKey={activeKey}>
    <div key={index.toString()} style={{background :  activeKey === index.toString() ? '#f5f5f5' : 'transparent'}}>
      <Accordion.Toggle
        as={Card.Header}
        style={{background: "transparent", border : "none"}}
        eventKey={index.toString()}
        onClick={() => handleToggle(index.toString())}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{display: "flex",alignItems : "center", gap :"6px"}}>
          <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >

            </div>
             <p style={{ marginBottom: "0px" }}>{data?.level === "" ? data.level_alias : data.level}</p>
          </div>
          <div style={{display: "flex", gap: "11px", alignItems : "center"}}>

          <p style={{marginBottom: "0px"}}>  <img
              src={data?.badge}
              alt="badge"
              style={{ height: "25px" }}
          /></p>
          {
            activeKey === index.toString() ? <i style={{color: "black"}} className="fa fa-angle-up" aria-hidden="true" />   :   <i style={{color: "black"}} className="fa fa-angle-down" aria-hidden="true" />
          }
          </div>
                  
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse
        eventKey={index.toString()}
      >
          <Card.Body
              className="check-user"
              style={{ height: "100%" }}
              dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(`<h4>${data?.level} :</h4> ${data?.description_level?.replace(/\n/g, '<br>') || ''}`)
              }}
          >

        </Card.Body>
      </Accordion.Collapse>
    </div>
  </Accordion>   
  )
}

export default LevelDescriptionView











