import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// import FanActiveSec from "./FanActiveSec";
// import FanExpiredSec from "./FanExpiredSec";
import {
  fetchActiveFollowersStart,
  fetchExpiredFollowersStart,
  fetchFollowersStart,
} from "../../store/actions/FollowAction";
import FanTabSec from "../Accounts/FansFollowing/Fans/FanTabSec";
import FanAllSec from "../Accounts/FansFollowing/Fans/FanAllSec";
import { t } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import FanActiveSec from "../Accounts/FansFollowing/Fans/FanActiveSec";
import FanExpiredSec from "../Accounts/FansFollowing/Fans/FanExpiredSec";
import SubscriberTabSec from "./SubscriberTabSec";
import AccessControlRestrict from "../Post/CreatePost/AccessControlRestrict";
import { components } from "react-select";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { abort } from "process";
import { getSubscriptionLevelsStart } from "../../store/actions/SubscriptionAction";
import "./style.css";
import SubscribersActiveSec from "./SubscribersActiveSec";

const SubscribersIndex = (props) => {
  const dispatch = useDispatch();
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [subLevelOp, setSubLevelOp] = useState([]);
  const { activeSubscribers } = useSelector((state) => state.subscriber);
  const { allSubLevelList } = useSelector((state) => state.subscriptions);
  const [selectedTip, setSelectedTip] = useState([]);




  const handleSelectTip = (data) => {

    setSelectedTip([...selectedTip, data]);

    const filtreSubLevelOp = subLevelOp.filter((ele)=> ele.id !== data?.id)

    setSubLevelOp(filtreSubLevelOp);
      
  }

  const handleRemoveTip = (data)=> {

    setSubLevelOp([...subLevelOp, data]);
    
    const filtreSelectedTip = selectedTip.filter((ele)=> ele.id !== data.id)

    setSelectedTip(filtreSelectedTip);

    
  }


  useEffect(() => {
    dispatch(getSubscriptionLevelsStart({ setFiltreTip : setSubLevelOp }));
  }, []);

  const [activeSec, setActiveSec] = useState("active-sec");

  // const changeSection = (event, type) => {
  //   setActiveSec(type);
  //   if (type === "active-sec") dispatch(fetchSubscribersStart());
  //   if (type === "expired-sec") dispatch(fetchExpiredFollowersStart());
  //   if (type === "all-sec") dispatch(fetchFollowersStart());
  // };

  // for subscriber level select list

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      // background: "#d4eefa",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: "400",
      padding: "3px",
      // order: 99,           <-- if you want this after input serach right side then uncomment
      order: 0,
    };

    const title = items.join(", ");
    const length = items.length;
    // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
    const label = `+ ${length} more...`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    console.log(
      "🚀 ~ MultiValue ~ index:",
      index,
      props.data.label,
      getValue()
    );
    const maxToShow = 3;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props}>
        <span style={{ fontSize: "12px" }}>{props.data.label}</span>
      </components.MultiValue>
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const handleChangeValue = (selected) => {
    setSelectedLevel(selected);
  };



  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left">
                  <h3>
                    <Link
                      className="bookmarkes-list"
                      to={"/home"}
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("fans")}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "5px" , justifyContent: "space-between" }}>
                
                <div className="chips-container-list" style={{display: "flex", gap: "5px"}}>


                  {
                      selectedTip?.length > 0 && (

                  <div className="active-chips-list-wrapper" >

                  {
                    selectedTip?.map((userL)=>{
                      return(
                  <div className="active-chips-list selected-active-tips" style={{ width: "auto" ,maxWidth: '100%', gap : "30px" , cursor: "default"}} >
                    <div className="active-list-icon-text-wrapper">
                      <img
                        style={{ height: "20px" }}
                        src={userL?.badge}
                        alt=""
                      />
                      <p style={{margin: "0", fontSize: "13px"}}>{userL?.level_alias}</p>
                    </div>
                    <div className="active-list-count-delete-w" style={{display: 'flex', alignItems: "center", gap: "5px"}}>

                    <p style={{margin: "0",fontSize: "13px"}}>(100)</p>
                     <button className="active-list-count-delete-btn"  onClick={()=>  handleRemoveTip(userL)}>
                         X
                     </button>
                    </div>
                  </div>
  
                      )
                    })
                  }
                  
                </div>

                      )
                    }
                    
                   {
                   subLevelOp.length > 0 && (

              <div className="active-chips-list-wrapper">

                {
                  subLevelOp.map((userL)=>{
                    return(
                <div className="active-chips-list" onClick={()=>  handleSelectTip(userL)}>
                  <div className="active-list-icon-text-wrapper">
                    <img
                      style={{ height: "20px" }}
                      src={userL?.badge}
                      alt=""
                    />
                    <p style={{margin: "0", fontSize: "13px"}}>{userL?.level_alias}</p>
                   </div>
                      <p style={{margin: "0",fontSize: "13px"}}>(100)</p>
                    </div>

                    )
                  })
                }
                
              </div>
                    )
                     }
                    
                </div>

                {
                   selectedTip?.length > 0 && (
                    <button onClick={()=> { 
                      setSelectedTip([]);
                      dispatch(getSubscriptionLevelsStart({ setFiltreTip : setSubLevelOp })) 
                    
                    }} style={{all: "unset", color: "#6661e7", fontSize: '14px', fontWeight: "500", cursor: "pointer"}}>Reset all</button>
                   )
                }

              


            </div>
            <div
              className="listing-tab"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >

              <div className="tab" role="tabpanel" style={{ width: "100%" }}>
                <div className="tab-content tabs">
                  <SubscribersActiveSec
                    activeSec={activeSec}
                    setActiveSec={setActiveSec}
                    subscribers={activeSubscribers}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  followers: state.follow.followers,
  activeFollowers: state.follow.activeFollowers,
  expiredFollowers: state.follow.expiredFollowers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default SubscribersIndex;
