import React from 'react'
import Select from "react-select";
import makeAnimated from "react-select/animated";

const AccessControlRestrict = ({optionsValue, Option , MultiValue , handleChangeValue , value , labelType , groupOptions }) => {
  return (
    <div style={{display: "flex", flexDirection: "column", gap :"5px", marginTop: "6px"}}>

         <p style={{all: "unset" , fontSize: "11px", fontWeight: "400"}}>{labelType}</p>

        <Select

        //  defaultValue={subLevelOptions[0]}
        //  isDisabled={false}
        //  isLoading={false}
         isClearable={true}
        //  isRtl={false}
        //  isSearchable={false}
         name="color"
         options={groupOptions ? groupOptions : optionsValue}
         // isMulti
         closeMenuOnSelect={false}
         hideSelectedOptions={false}
         components={{ Option , MultiValue, animatedComponents: makeAnimated()}}
         onChange={handleChangeValue}
         allowSelectAll={true}
         value={value}

           />

    </div>
  )
}

export default AccessControlRestrict
