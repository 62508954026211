import React, { useEffect, useRef, useState } from 'react'
import UseIntersectionObserver from './UseIntersectionObserver';

import ReactPlayer from 'react-player';

const VideoComponent = ({src}) => {

	const videoRef = useRef(null);

  const handleIntersection = (entry) => {
    if (entry.isIntersecting) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const { observe, unobserve } = UseIntersectionObserver(handleIntersection);

  useEffect(() => {
    if (videoRef.current) {
      observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        unobserve(videoRef.current);
      }
    };
  }, [observe, unobserve]);

  return (
    <video 
      ref={videoRef}
      src={src} 
			style={{
        width: "100%",
        height: "100%"
      }} 
			autoPlay
			muted
      loop 
    />
  )
}

export default VideoComponent